import React from 'react'
import classNames from 'classnames'

export default function LoadingIndicator({ className }: { className?: string }) {
  const rootClassName = classNames('inline-flex justify-center items-center', className)
  return (
    <div className={rootClassName}>
      <div
        style={{ borderBottom: '2px solid white', width: '12px', height: '12px' }}
        className="animate-spin rounded-full"
      ></div>
    </div>
  )
}
