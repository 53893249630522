import { observer } from 'mobx-react'
import React from 'react'
import { Controller } from 'react-hook-form'
import Select from './components/form/Select'
import { PreviewTable } from './components/preview-table'
import { applicantBatchStore as store } from './store'
import CsvProblems from './components/csv-problems'
import FormField from './components/form/FormField'
import FileInput from './components/form/FileInput'
import Success from './components/alerts/success'
import LoadingIndicator from './components/loading-indicator'
import ConfirmationModal from './components/confirmation-modal'
import SendToSuperAdminModal from './components/upload-for-superadmin-modal'
import Description from './components/description'
import Controls from './components/controls'
import useApplicantBatchForm from './useApplicantBatch'

export const ApplicantBatch = observer(() => {
  const {
    register,
    handleSubmit,
    onSubmit,
    org,
    control,
    errors,
    onConfirmClick,
    onSuperAdminSendConfirmClick,
    onModalCancelClick,
  } = useApplicantBatchForm()

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="tw">
      <Description />
      {org && (
        <ConfirmationModal
          onCancelClick={onModalCancelClick}
          onConfirmClick={onConfirmClick}
          open={store.confirmationOpen}
          orgName={store.getOrgName(org)}
        />
      )}
      <SendToSuperAdminModal
        onCancelClick={() => (store.sendToSuperAdminOpen = false)}
        onConfirmClick={onSuperAdminSendConfirmClick}
        open={store.sendToSuperAdminOpen || false}
      />
      <div className="container mx-auto max-w-2xl border-none">
        <FormField className="flex-1" name="org" label="Organization" errors={errors}>
          <Select options={store.orgOptions} disabled={store.loading} {...register('org')} />
        </FormField>
        <FormField className="flex-1" name="file" label="File" errors={errors}>
          <Controller
            control={control}
            name="file"
            render={({ field: { onChange, value } }) => (
              <FileInput className="h-20" id="file" onChange={onChange} value={value} />
            )}
          />
        </FormField>
      </div>
      <PreviewTable content={store.csv} />
      {store.response && store.response?.success == false && (
        <CsvProblems className="mt-8" csvProblems={store.response.csvProblems} message={store.response.message} />
      )}
      {store.response?.success && <Success className="mt-8" title="Done" description={store.response.message} />}
      {store.loading && <LoadingIndicator />}
      <Controls />
    </form>
  )
})
