import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Applicant } from '.'
import { getPath } from '../get-path'

export const register = () => {
  const path = getPath()

  if (!/\/menu-applicant-new/i.test(path)) {
    return
  }

  const domContainer = document.querySelector('#menu-applicant-new')

  ReactDOM.render(<Applicant />, domContainer)
}
