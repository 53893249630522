import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { QuestionInfo } from '../../api/assessment'

interface Props {
  questionInfo: QuestionInfo
}

export const ForcedRankInput = ({ questionInfo }: Props) => {
  const { reactions } = questionInfo || {}

  const { register } = useFormContext()

  return (
    <div className="radiobtnbox">
      <div className="radiobtnboxinner">
        {reactions.map((rea, reaIndex) => (
          <label key={rea.reactionId} htmlFor={`optForcedrank-${rea.reactionId}`}>
            <input
              type="radio"
              id={`optForcedrank-${rea.reactionId}`}
              defaultValue={reaIndex}
              {...register(`reactionResponse.0.response`)}
            />
            <span>{rea.reactionText}</span>
          </label>
        ))}
      </div>
    </div>
  )
}
