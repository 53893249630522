import React from 'react'

export default function Description() {
  return (
    <div className="mx-auto container border-none max-w-2xl rounded mt-8">
      <div className=" p-8 bg-gray-100 border-none ">
        <h1 className="text-xl font-bold p-0">Batch Upload Applicants</h1>
        <p>Please upload the CSV file that you downloaded from ERAS (or other application portal) here.</p>
        <p className="mt-4">Your file MUST have the following column headings and information:</p>
        <ul className="mt-1 pl-4">
          <InstructionLine>First Name</InstructionLine>
          <InstructionLine>Last Name</InstructionLine>
          <InstructionLine>AAMC ID</InstructionLine>
          <InstructionLine>E-mail</InstructionLine>
        </ul>
        <p className="italic">
          If your applicants do not have a unique AAMC ID (e.g., non ACGME fellowships), please contact your SurgWise
          project lead to receive unique ID codes.
        </p>
        <p className="mt-4">Please also include any of the following data you have with these column headings:</p>
        <ul className="mt-1 pl-4">
          <InstructionLine>Gender</InstructionLine>
          <InstructionLine>Self Identify</InstructionLine>
          <InstructionLine>Ethnicity</InstructionLine>
          <InstructionLine>Date of Birth</InstructionLine>
          <InstructionLine>Medical Degree</InstructionLine>
          <InstructionLine>Medical School of Graduation</InstructionLine>
          <InstructionLine>USMLE Step 1 Score</InstructionLine>
          <InstructionLine>USMLE Step 2 CK Score</InstructionLine>
        </ul>
        <p className="mt-2">
          If there is any other relevant applicant information you would like us to include in our analyses, please also
          include detailed column headings.
        </p>
      </div>
    </div>
  )
}

function InstructionLine({ children }) {
  return (
    <li className="list-none flex items-center">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clipRule="evenodd" />
      </svg>

      {children}
    </li>
  )
}
