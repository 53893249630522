import * as React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { config } from './config'
import './index.css'

const GoogleCaptchaProvider = GoogleReCaptchaProvider as any

export const CaptchaProvider = (props) => (
  <GoogleCaptchaProvider reCaptchaKey={config.auth.captchaKey} language="en-US">
    {props.children}
  </GoogleCaptchaProvider>
)
