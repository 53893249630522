import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Assessment } from '.'
import { getPath } from '../get-path'

export const register = () => {
  const path = getPath()

  if (!/\/assessment/i.test(path)) {
    return
  }

  const domContainer = document.querySelector('#assessment')

  ReactDOM.render(<Assessment />, domContainer)
}
