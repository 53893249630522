import React from 'react'
import { XCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
interface Props {
  children: React.ReactNode
  title: string
  instructions: string
  className?: string
}

export default function Errors({ title, instructions, className, children }: Props) {
  const rootClassName = classNames('rounded-md bg-red-50 p-4 container mx-auto border-none max-w-2xl', className)

  return (
    <div className={rootClassName}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          <div className="mt-2 text-sm text-red-700">{children}</div>
          <h3 className="text-sm font-medium text-red-800 mt-2">{instructions}</h3>
        </div>
      </div>
    </div>
  )
}
