import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Login } from '.'
import { getPath } from '../get-path'
import { CaptchaProvider } from '../providers'

export const register = () => {
  const path = getPath()

  if (!/\/login/i.test(path)) {
    return
  }

  document.querySelector('html').classList.add('login-root')
  document.querySelector('body').classList.add('login-main')

  const domContainer = document.querySelector('#login-form')

  ReactDOM.render(
    <CaptchaProvider>
      <Login />
    </CaptchaProvider>,

    domContainer,
  )
}
