import axios from 'axios'
import { config } from '../config'

export const api = axios.create({
  baseURL: config.apiBaseUrl,
})

api.interceptors.response.use(
  (response) => response,
  (err) => {
    if (401 === err?.response?.status) {
      const url = encodeURIComponent(`${window.location.pathname}${window.location.search}`)

      if (!/login[.]asp/i.test(window.location.href)) {
        window.location.href = `/login.asp?return_uri=${url}`
      }

      return
    }

    return Promise.reject(err)
  },
)
