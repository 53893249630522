import { makeAutoObservable } from 'mobx'
import * as qs from 'query-string'
import * as loginApi from '../api/login'
import { TokenResponse } from '../services/auth'
import { cookies } from '../services/cookies'

interface UserLoginInfo {
  username: string
  password: string
  captcha: string
}

export enum Roles {
  SUPERADMIN = '1',
  ADMIN = '2',
  APPLICANT = '3',
}

const tokenCookieName = 'access_token'

class AuthStore {
  auth = {
    token: undefined,
    expiration: undefined,
  }

  constructor() {
    makeAutoObservable(this)
  }

  async login(userLoginInfo: UserLoginInfo) {
    const { data } = await loginApi.login({
      ...userLoginInfo,
      username: userLoginInfo.username.replace(/^\s|\s$/g, ''),
      password: userLoginInfo.password.replace(/^\s|\s$/g, ''),
    })
    const needsPasswordReset = !(data.userInfo.forcePasswordReset === 'true')

    this.auth = data

    cookies.set(tokenCookieName, data.token, {
      expires: new Date(data.expiration),
    })

    cookies.set('uid', +data.userInfo.uid)
    cookies.set('role', +data.userInfo.role)
    cookies.set('fpr', needsPasswordReset ? 0 : 1)
    cookies.set('oid', +data.userInfo.oid)

    cookies.set('userInfo', data.userInfo)

    if (needsPasswordReset) {
      window.location.href = '/password_edit.asp'

      return
    }

    const { return_uri: returnUri } = qs.parse(location.search)

    if (returnUri) {
      window.location.href = decodeURIComponent(returnUri as string)

      return
    }

    switch (+data.userInfo.role) {
      case 1:
        window.location.href = '/menu-superadmin.asp'
        break
      case 2:
        window.location.href = '/menu-admin.asp'
        break
      case 3:
      default:
        window.location.href = '/menu-applicant-new.asp'
        break
    }
  }

  logout() {
    cookies.remove(tokenCookieName)
  }

  getUserInfo() {
    return cookies.get('userInfo') as TokenResponse['userInfo']
  }
}

export const authStore = new AuthStore()
