import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm } from 'react-hook-form'
import { config } from '../config'
import { authStore } from '../stores/auth-store'

const captchaAction = 'login'

export const Login = () => {
  const [loading, setLoading] = React.useState(false)
  const [captchaResponse, setCaptchaResponse] = React.useState<string>()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
  } = useForm()

  const onSubmit = React.useCallback(
    async (data) => {
      setLoading(true)

      const res = await executeRecaptcha(captchaAction)
      setCaptchaResponse(res)

      // good to login
      try {
        await authStore.login({
          ...data,
          captcha: res || captchaResponse,
        })
      } catch (err) {
        setError('password', { type: 'validate', message: 'Username or password is incorrect' })

        setLoading(false)
        setFocus('password')
        // console.error(err)
      }

      // don't clear loading on success because it's transitioning to a legacy page
    },
    [!!executeRecaptcha, errors],
  )

  React.useEffect(() => {
    setFocus('username')
  }, [])

  return (
    <div className="mt-6 mb-2">
      {errors.password?.type === 'validate' && (
        <div className="error_msg">
          The username and/or password does match our records. Please try again.
          <br />
          <br />
          If you need help logging in, click the "Forgot Password" button below.
        </div>
      )}

      {errors.captcha && <div className="error_msg">{errors.captcha.message}</div>}

      <form onSubmit={handleSubmit(onSubmit)} className={loading ? 'disabled' : ''}>
        <>
          Username:
          <br />
          <input type="text" size={40} {...register('username', { required: true })} disabled={loading} />
          <br />
          <br />
        </>

        <>
          Password:
          <br />
          <input type="password" size={40} {...register('password', { required: true })} disabled={loading} />
          <br />
          <br />
        </>

        {config.auth.enableCaptcha && (
          <>
            <GoogleReCaptcha action={captchaAction} onVerify={setCaptchaResponse} />
          </>
        )}

        <>
          <input type="hidden" name="Action" value="Go" />
          <input type="submit" value="Submit" name="cmdButton" disabled={loading} />
          <input
            type="button"
            value="Forgot Password"
            name="cmdButton"
            id="cmdButton"
            disabled={loading}
            onClick={() => (window.location.href = 'forgot_password.asp')}
          />
        </>

        {loading && (
          <div className="text-center mt-2">
            <FontAwesomeIcon icon={faCircleNotch} spin />
            <span className="ml-1">Logging you in...</span>
          </div>
        )}
      </form>
    </div>
  )
}
