import React, { forwardRef } from 'react'
import classNames from 'classnames'

type Kind = 'primary' | 'secondary' | 'neutral'
const classesByKind: Record<Kind, string> = {
  primary: 'bg-green-500 hover:bg-green-500 focus:ring-gray-500 text-white',
  secondary: 'h-12 bg-blue-400 text-white',
  neutral: 'bg-gray-300 hover:bg-gray-300 focus:ring-gray-300',
}

interface ButtonProps extends React.ComponentPropsWithRef<'button'> {
  loading?: boolean
  disabled?: boolean
  className?: string
  children: React.ReactNode
  kind: Kind
}

export default forwardRef<HTMLButtonElement, ButtonProps>(function Button({
  loading,
  disabled,
  children,
  kind,
  className,
  ...rest
}, ref) {
  const classes = classNames(
    className,
    classesByKind[kind],
    (disabled || loading) && 'opacity-50 cursor-default',
    'w-32 m-0 px-4 py-2 text-center border border-transparent text-sm font-medium rounded shadow-sm focus:ring-offset-2 focus:outline-none focus:ring-2',
  )

  return (
    <button disabled={disabled} type="button" className={classes} {...rest} ref={ref}>
      {children}
    </button>
  )
})
