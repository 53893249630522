import classNames from 'classnames'
import React, { useRef } from 'react'
import { DocumentTextIcon } from '@heroicons/react/solid'

export interface InputProps extends React.ComponentPropsWithRef<'input'> {
  error?: boolean
  className?: string
}

export default function FileInput({ value, onChange, className, id }) {
  const ref = useRef<HTMLInputElement>()
  const fileName = value?.name

  const labelClassName = classNames(
    'flex',
    {
      'bg-gray-100 p-2 rounded text-center items-center justify-center flex-1': fileName,
      'justify-start text-left': !fileName,
    },
    className,
  )

  const onFileSelect = (e) => {
    onChange(e.target.files[0])

    // https://stackoverflow.com/a/35323290/3775416
    ref.current.type = ''
    ref.current.type = 'file'
  }

  return (
    <div className="flex">
      {fileName ? (
        <label className={labelClassName} htmlFor={id}>
          <>
            <DocumentTextIcon className="text-blue-400 h-16" />
            <span className="text-bold">{fileName}</span>
          </>
        </label>
      ) : (
        <label className="bg-blue-400 text-white text-center p-3 px-16 rounde" htmlFor={id}>
          Select a File
        </label>
      )}

      <input
        id={id}
        type="file"
        accept=".csv,.xlsx"
        onChange={onFileSelect}
        className={classNames('opacity-0 absolute')}
        ref={ref}
      />
    </div>
  )
}
