import React from 'react'
import { CheckCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

interface Props {
  title: string
  description: string
  className?: string
}

export default function Success({ title, description, className }: Props) {
  const rootClassName = classNames('rounded-md bg-green-50 p-4 container mx-auto border-none max-w-2xl', className)

  return (
    <div className={rootClassName}>
      <div className="flex text-center">
        <div className=""></div>
        <div className="ml-3 flex-1 items-center">
          <div className="flex items-center justify-center">
            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
            <h3 className="text-sm font-medium inline text-green-800">{title}</h3>
          </div>
          <div className="mt-2 text-sm text-green-700">
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
