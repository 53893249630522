import { Duration } from 'luxon'
import { observer } from 'mobx-react'
import * as React from 'react'
import { assessmentStore } from '../../stores/assessment-store'

interface Props {
  time: number
}

setInterval(() => assessmentStore.tickQuestionTimer(), 1000)

export const ElapsedTime = observer(() => {
  return (
    <div className="timer">
      Elapsed time{' '}
      <span>{Duration.fromObject({ seconds: assessmentStore.questionTimer || 0 }).toFormat('hh:mm:ss')}</span>
    </div>
  )
})
