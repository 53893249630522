import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { ApplicantMenu } from '.'

export const register = () => {
  const domContainer = document.querySelector('#applicant-menu')

  if (domContainer) {
    ReactDOM.render(<ApplicantMenu />, domContainer)
  }
}
