import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import styles from './bundle-menu.module.css'

export const BundleMenu = observer(({ dashboard, selectedDashboard, bundleClick }) => {
  return (
    <ul className="nav nav-tabs nav-tabs--vertical" role="navigation">
      {dashboard?.map((d) =>
        !d?.bundle?.bundleId ? null : (
          <li
            className={`nav-item ${styles.bundleMenuBlock} ${
              d.bundle.bundleId === selectedDashboard?.bundle?.bundleId ? styles.active : ''
            }`}
            key={d.bundle.bundleId}
            onClick={bundleClick(toJS(d))}
          >
            <div className={`nav-link ${d.bundle.bundleId === selectedDashboard?.bundle?.bundleId ? 'active' : ''}`}>
              <div className="row assessment_list">
                <div className="col-md-2 px-0">
                  <div className="icon_img">
                    <img src={`images/${d.org.orgLogo || 'no_image.png'}`} className="logo_img" />
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="assess_cnt ellipsis_none">
                    {d.totalAnswered >= d.totalQuestions && (
                      <>
                        <img className="tic-img" src="images/check.png" />
                        <h2>{d.bundle.bundleExternalName}</h2>
                      </>
                    )}

                    {d.totalAnswered < d.totalQuestions && (
                      <>
                        <a href="q_instruction-new.asp?bid=%=BundleID%"></a>
                        <h2>{d.bundle.bundleExternalName}</h2>
                      </>
                    )}

                    {/* TODO: @brunolm this is supposed to be here but it doesnt show in production */}
                    {/* <p>{d.state.stateName}</p> */}
                  </div>
                </div>
              </div>
            </div>
          </li>
        ),
      )}
    </ul>
  )
})
