import { getHeaders } from '../api/get-headers'
import { api } from '../api/api'

export interface ApplicantCsvUpload {
  id: string
  orgName: string
  status: string
  admin: string
  uploadedAt: string
  fileName: string
}
export const getApplicantsCsvUploads = () => {
  return api.get<ApplicantCsvUpload[]>('/Applicant/csv-uploads', {
    ...getHeaders(),
  })
}

export const downloadFile = (uploadId: number) => {
  return api.get(`/Applicant/download-csv/${uploadId}`, {
    ...getHeaders({ download: true }),
  })
}
