import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { QuestionInfo } from '../../api/assessment'
import styles from './open-response-input.module.css'

interface Props {
  questionInfo: QuestionInfo
}

const maxLength = 1000

export const OpenResponseInput = ({ questionInfo }: Props) => {
  const { register, watch } = useFormContext()

  const openResponse = register('openResponse', {
    max: 1000,
  })

  const numCharacters = (watch('openResponse') || '').length

  if (!questionInfo?.question?.qisOpenResponse) {
    return null
  }

  return (
    <div className="mt-4">
      <h3 className="pb-2">In this area, you can provide up to ONE additional response you might take in response to this scenario (optional):</h3>
      <div style={{ display: 'inline-block', minWidth: '70%', maxWidth: '100%' }}>
        <textarea
          className={`form-control ${styles.textarea}`}
          maxLength={maxLength}
          rows={5}
          {...openResponse}
        ></textarea>
        <span>
          {numCharacters} out of {maxLength} maximum characters
        </span>
      </div>
    </div>
  )
}
