import { api } from '../api'
import { getHeaders } from '../get-headers'

export interface Dashboard {
  now: string
  applicantBundleAssignment: ApplicantBundleAssignment
  bundle: Bundle
  org: Org
  orgApplicants: OrgApplicants
  state: State
  totalQuestions: number
  totalAnswered: number
  totalTime: number
}

export interface State {
  stateId: number
  stateName: string
  stateAbbrev: string
}

export interface OrgApplicants {
  orgApplicantsId: number
  orgId: number
  userId: number
  dateAdded: string
  scoringStatus?: any
  fitRecommend?: any
  resultsSetntoProgram?: any
  programSentInterviewInvite?: any
  latestdateUpdate?: any
}

export interface Org {
  orgId: number
  orgName: string
  stateId: number
  orgActive: number
  emailFirstSubject: string
  emailFirst: string
  emailSecondSubject: string
  emailSecond: string
  orgLogo: string
  orgAttachment: string
}

export interface Bundle {
  bundleId: number
  bundleInternalName: string
  bundleExternalName: string
  instructions: string
}

export interface ApplicantBundleAssignment {
  appBundleAssignId: number
  userId: number
  bundleId: number
  dateAssigned: string
  bundleStartTime: string
  bundleEndTime?: any
  totalTime?: any
  orgId: number
}

export const getDashboard = () => {
  return api.get<Dashboard[]>('/applicant/dashboard', {
    ...getHeaders(),
  })
}
