import React from 'react'
import classNames from 'classnames'

export type FormLabelProps = React.ComponentPropsWithoutRef<'label'>

export default function FormLabel({ htmlFor, className, ...rest }: FormLabelProps) {
  return (
    <label className={classNames(className, 'block text-sm font-medium text-gray-700')} htmlFor={htmlFor} {...rest} />
  )
}
