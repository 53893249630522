import classNames from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'
import { useEffect } from 'react'
import { applicantCsvUploadsStore as store } from './store'

export const ApplicantCsvUploads = observer((props) => {
  useEffect(() => {
    store.getUploads()
  }, [])

  if (!store?.uploads?.length) {
    return <h1>No uploads found.</h1>
  }
  return (
    <div className="container border-none">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto ">
          <div className="py-2 align-middle inline-block sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"></div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    File Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Organization
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Admin
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Time
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only"></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {store.uploads.map((upload) => {
                  const statusClassName = classNames('px-6 py-4 whitespace-nowrap text-sm text-gray-500', {
                    'text-red-600': upload.status.toLowerCase() === 'failed',
                    'text-green-400': upload.status.toLowerCase() === 'success',
                  })
                  return (
                    <tr key={upload.id}>
                      <td>{upload.fileName}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {upload.orgName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{upload.admin}</td>
                      <td className={statusClassName}>{upload.status}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{upload.uploadedAt}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm fon/t-medium">
                        <a
                          onClick={() => store.downloadFile(upload.id)}
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
})
