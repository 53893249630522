import React from 'react'
import ErrorAlert from '../alerts/errors'
import { BatchInsertResponse } from '../../api'

type Props = {
  csvProblems: BatchInsertResponse['data']['csvProblems']
  message: string
  className?: string
}

const CsvProblems = ({ csvProblems, message, className }: Props) => {
  const { lineProblems: lineProblemsList, problems } = csvProblems

  return (
    <ErrorAlert
      className={className}
      title="Uh oh...there is an error with the CSV file"
      instructions="Please edit the above issue(s) with your file and upload again."
    >
      <div>
        <h1>{message}</h1>
        {problems && (
          <ul>
            {problems.map((problem) => (
              <li>{problem}</li>
            ))}
          </ul>
        )}
        {lineProblemsList?.map((lineProblems) => (
          <div>
            <span>in line {lineProblems.line}</span>
            <ul className="mt-0">
              {lineProblems.problems.map((problem) => (
                <li>{problem}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </ErrorAlert>
  )
}

export default CsvProblems
