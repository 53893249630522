import { api } from '../api'
import { getHeaders } from '../get-headers'

export interface QuestionInfo {
  question: Question
  questionType: QuestionType
  reactions: Reaction[]
  responses: Response[]
  summaryInfo: SummaryInfo
}

export interface Response {
  responseOptionId: number
  qId: number
  responseOptionText: string
  displayOrder: number
}

export interface Reaction {
  reactionId: number
  qId: number
  reactionText: string
  displayOrder: number
  responseType?: any
}

export interface QuestionType {
  qTypeId: number
  qTypeText: string
}

export interface Question {
  qId: number
  qNickname: string
  qText: string
  qTypeId: number
  qPath: string
  qOpenResponseText: string
  qResponsePath: string
  qisOpenResponse: boolean
  qflag: boolean
}

export interface SummaryInfo {
  currentStep: number
  timeOnCurrent: number
  totalQuestions: number
}

export const getNextquestion = (bundleId: number) => {
  return api.get<QuestionInfo>(`/assessment/nextquestion?bundleId=${bundleId}`, {
    ...getHeaders(),
  })
}

export const previewQuestion = (bundleId: number, questionIdx: number) => {
  return api.get<QuestionInfo>(`/assessment/preview-question?bundleId=${bundleId}&questionIdx=${questionIdx}`, {
    ...getHeaders(),
  })
}
