import { faCaretDown, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { cookies } from '../../services/cookies'
import './applicant-menu.css'

export const ApplicantMenu = () => {
  const [show, setShow] = React.useState<boolean>(false)
  const userInfo = cookies.get('userInfo')

  const toggle = React.useCallback(() => {
    setShow((s) => !s)
  }, [])

  return (
    <div className="applicant-menu float-right pt-3">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center mr-3">
          <h5>
            {' '}
            <a href="http://support.selectwise.org/" target="_blank" className="mr-4 text-black">
              Help Center
            </a>
          </h5>
          <h5>
            {' '}
            <a href="http://support.selectwise.org/hc/en-us/requests/new" target="_blank" className="mr-2 text-black">
              Contact Us
            </a>
          </h5>
        </div>
        <div className={`dropdown ${show ? 'show' : ''}`} onClick={toggle}>
          <h5 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <FontAwesomeIcon icon={faUser} />

            <span className="ml-2 mr-1">
              {userInfo?.firstName} {userInfo?.lastName}
            </span>

            <FontAwesomeIcon icon={faCaretDown} />
          </h5>
          <div className={`dropdown-menu ${show ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" href="../password_edit.asp">
              Your Account
            </a>

            <a className="dropdown-item" href="../logout.asp">
              Log Out
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
