import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'
import * as qs from 'query-string'
import * as React from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { HtmlContent } from '../shared/html-content'
import { assessmentStore } from '../stores/assessment-store'
import { authStore, Roles } from '../stores/auth-store'
import './assessment.css'
import { ElapsedTime } from './elapsed-time'
import { ForcedRankInput } from './forced-rank-input'
import { OpenResponseInput } from './open-response-input'
import { ResponseInput } from './response-input'

export const Assessment = observer(() => {
  const [loading, setLoading] = React.useState(true)

  const { question, questionType, summaryInfo } = assessmentStore.questionInfo || {}
  const userInfo = authStore.getUserInfo()
  const form = useForm()

  const saveAndExit = React.useCallback(
    (e) => {
      e.preventDefault()
      window.onbeforeunload = undefined

      const fn = async () => {
        try {
          const data = {
            ...form.getValues(),
            questionId: question.qId,
          }

          await assessmentStore.saveAndExit(data)
          window.location.href = 'menu-applicant-new.asp'
        } catch (err) {
          // TODO: @brunolm handle errors
          console.error(err)
        }
      }
      fn()
    },
    [question?.qId],
  )

  const checkAnsweredAllQuestions = (values) => {
    if (!assessmentStore?.questionInfo?.reactions?.length || !assessmentStore?.questionInfo?.responses?.length) {
      return true
    }

    for (const rp of values.reactionResponse) {
      if (!rp.response) {
        return false
      }
    }

    return true
  }

  const saveAndContinue = React.useCallback(
    (values) => {
      const data = {
        ...values,
        questionId: question.qId,
      }

      let canSubmit = checkAnsweredAllQuestions(values)
      if (!canSubmit) {
        return
      }

      const fn = async () => {
        try {
          if (userInfo.role === Roles.APPLICANT) {
            await assessmentStore.saveAnswers(data)
          }

          const { id: bundleId } = qs.parse(location.search)

          if (userInfo.role === Roles.APPLICANT) {
            assessmentStore.getNextQuestion(+bundleId)
          } else {
            assessmentStore.previewNextQuestion(+bundleId)
          }
        } catch (err) {
          // TODO: @brunolm handle errors
          console.error('could not save answers', err)
        }
      }

      fn()
    },
    [question?.qId],
  )

  useEffect(() => {
    form.reset()
    form.clearErrors()
    form.unregister(undefined, {
      keepError: false,
      keepDirty: false,
    })
  }, [question?.qId])

  const submitError = React.useCallback((data) => {
    console.log('submit error', data)
  }, [])

  const goHome = React.useCallback(() => {
    window.location.href = 'menu-applicant-new.asp'
  }, [])

  const done = !question

  React.useEffect(() => {
    const { id: bundleId } = qs.parse(location.search)

    const fn = async () => {
      if (userInfo.role === Roles.APPLICANT) {
        await assessmentStore.getNextQuestion(+bundleId)
      } else {
        await assessmentStore.previewCurrentQuestion(+bundleId)
      }
      setLoading(false)
    }
    fn()

    if (!done) {
      window.onbeforeunload = (e) => {
        return ''
      }
    } else {
      window.onbeforeunload = undefined
    }
  }, [done])

  if (loading) {
    return (
      <div className="text-center py-8">
        <FontAwesomeIcon icon={faCircleNotch} spin />
        <span className="pl-2">Loading assessment...</span>
      </div>
    )
  }

  if (done) {
    return (
      <div className="text-center py-8">
        <img src="images/selectwise_logo.jpg" alt="" />
        <h1 className="pt-2">Applicant Fit Assessment for {userInfo.firstName}</h1>
        <p className="py-2">
          Thank you for answering all of the questions.
          <br />
          Your answers have been saved and will be submitted to the program shortly.
        </p>
        {userInfo.role === Roles.APPLICANT && (
          <button className="btn btn-primary" onClick={goHome}>
            Return to Main Menu
          </button>
        )}
      </div>
    )
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(saveAndContinue, submitError)} className={''}>
        <div className="content-wrapper">
          <div className={`container p-3 pt-4`}>
            <div className={`flex gap-1`}>
              <div className="flex flex-1 items-center">
                <h3>Applicant Fit Assessment for {userInfo.firstName}</h3>
              </div>

              <div className="flex flex-col flex-initial">
                <div className="pb-1">
                  Item {summaryInfo?.currentStep || 0} of {summaryInfo?.totalQuestions || 0}
                </div>
                {userInfo.role === Roles.APPLICANT && <ElapsedTime />}
              </div>
            </div>

            <hr />

            <div className="flex flex-1 mt-4">
              <h3 className="text-danger mr-2">Q.</h3>
              <HtmlContent html={question?.qText} />
            </div>

            <div>
              <div className="p-4 flex imgReducecenter">
                {questionType?.qTypeId === 2 && (
                  <div className="xxx flex-1">
                    <img className="max-w-full mb-3" src={`uploads/${question.qPath}`} />
                  </div>
                )}

                {questionType?.qTypeId === 3 && (
                  <div className="xxx flex-1">
                    <video className="w-100" controls>
                      <source src={`uploads/${question.qPath}`} type="video/mp4" />
                    </video>
                  </div>
                )}
              </div>

              <div className="p-4 flex imgReducecenter">
                {/mp4$/i.test(question?.qResponsePath) && question?.qResponsePath && (
                  <div className="xxx flex-1">
                    <video className="w-100" controls>
                      <source src={`uploads/${question?.qResponsePath}`} type="video/mp4" />
                    </video>
                  </div>
                )}

                {!/mp4$/i.test(question?.qResponsePath) && question?.qResponsePath && (
                  <div className="xxx flex-1">
                    <img className="w-50" src={`uploads/${question?.qResponsePath}`} />
                  </div>
                )}
              </div>
            </div>

            {questionType?.qTypeId === 4 && <ForcedRankInput questionInfo={assessmentStore.questionInfo} />}

            <ResponseInput questionInfo={assessmentStore.questionInfo} />

            <OpenResponseInput questionInfo={assessmentStore.questionInfo} />

            <hr />

            <div className="mt-4 mb-2 text-right">
              <button type="submit" style={{ display: 'none' }}>
                Save and continue
              </button>
              {userInfo.role === Roles.APPLICANT && (
                <button className="btn btn-link mr-3" onClick={saveAndExit}>
                  Save and Resume later
                </button>
              )}
              <button className="btn btn-success" type="submit">
                Save and Continue
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  )
})
