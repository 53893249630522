import { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { applicantBatchStore as store } from './store'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'

const schema = yup.object().shape({
  org: yup.string().required('Please, select an organization'),
  file: yup.mixed().test('presence', 'Please, uploadad a csv file', (value) => {
    return value
  }),
})

const useApplicantBatch = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({ resolver: yupResolver(schema) })
  const file = watch('file')
  const org = watch('org')

  useEffect(() => {
    const showPreview = async () => {
      if (file) {
        store.file = file
        await store.parseCsv(file)
      }
    }

    showPreview()
  }, [file])

  useEffect(() => {
    store.fetchOrgs()
  }, [])

  const onSubmit = async () => {
    store.setConfirmationOpen(true)
  }

  const onConfirmClick = async () => {
    store.setConfirmationOpen(false)
    await store.batchInsert(org)
    setValue('file', undefined)
  }

  const onSuperAdminSendConfirmClick = async () => {
    const orgId = getValues('org')
    store.uploadCsvForSuperAdmin(orgId)
  }

  const onModalCancelClick = () => {
    store.setConfirmationOpen(false)
  }

  const payload = {
    register,
    handleSubmit,
    control,
    errors,
    onSubmit,
    org,
    onConfirmClick,
    onSuperAdminSendConfirmClick,
    onModalCancelClick,
  }

  return payload
}

export default useApplicantBatch
