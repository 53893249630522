import * as React from 'react'

export const HtmlContent = ({ html }) => {
  const content = (() => {
    const d = document.createElement('div')
    d.innerHTML = html

    return d.childNodes[0].nodeValue
  })()

  return <span dangerouslySetInnerHTML={{ __html: content }} />
}
