import { api } from '../api/api'
import { getHeaders } from '../api/get-headers'

export interface ErrorItem {
  message: string
  line: number
}
interface Org {
  orgName: string
  orgId: string
}

export interface BatchInsertResponse {
  data: {
    message: string
    success: boolean
    totalNew: number
    totalExisting: number
    csvProblems?: {
      lineProblems?: Array<{ line: number; problems: Array<string> }>
      problems?: Array<string>
    }
  }
}

export const batchInsert = (orgId: string, data: FormData) => {
  const endpoint = `/applicant/batch-insert?orgId=${orgId}`
  return api.post<FormData, BatchInsertResponse>(endpoint, data, {
    ...getHeaders(),
  })
}

export const getUserOrgs = () => {
  return api.get<Org[]>('/Org/user-orgs', {
    ...getHeaders(),
  })
}

export const uploadCsvForSuperadmin = (orgId: string, data: FormData) => {
  const endpoint = `/applicant/upload-csv?orgId=${orgId}`
  return api.post<FormData, BatchInsertResponse>(endpoint, data, {
    ...getHeaders(),
  })
}
