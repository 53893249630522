import { makeAutoObservable, toJS } from 'mobx'
import { BatchInsertResponse, batchInsert, uploadCsvForSuperadmin, getUserOrgs } from './api'
import Papa from 'papaparse'
import { Option } from './components/form/Select'
import { authStore, Roles } from '../stores/auth-store'

export interface CSVApplicant {
  'First Name': string
  'Last Name': string
  'AAMC ID': string
  'E-mail': string
  Gender: string
  'Date of Birth': string
  'Medical Degree': string
  'Medical School of Graduation': string
  'USMLE Step 1 Score': string
  'USMLE Step 2 CK Score': string
}

class ApplicantBatchStore {
  public csv: CSVApplicant[]
  public file: File
  public loading: boolean
  public response: BatchInsertResponse['data']
  public orgOptions: Option[]
  public loadingOrgs: boolean
  public sendToSuperAdminOpen: boolean
  public errorCount: number
  public confirmationOpen: boolean

  constructor() {
    this.errorCount = 0
    this.sendToSuperAdminOpen = false
    this.confirmationOpen = false
    makeAutoObservable(this)
  }

  get hasSuccess() {
    return this.response?.success
  }

  get hasError() {
    return this.response && !this.response.success
  }

  setConfirmationOpen(isOpen) {
    this.confirmationOpen = isOpen
  }

  onCancelClick() {
    window.history.back()
  }

  getOrgName(orgId: number | string) {
    const option = this.orgOptions.find((option) => option.value.toString() === orgId.toString())

    const { label } = option
    return label
  }

  parseCsv(file): Promise<CSVApplicant[]> {
    return new Promise((resolve) => {
      const parseConfig: Papa.ParseConfig = {
        worker: true,
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          this.csv = results.data
          this.response = null
          resolve(results.data)
        },
      }
      Papa.parse(file, parseConfig)
    })
  }

  async batchInsert(orgId: string) {
    this.loading = true

    const file = this.file
    const formData = new FormData()
    formData.append('file', file)

    try {
      const response = await batchInsert(orgId, formData)
      this.response = response.data
      this.errorCount = 0
    } catch (ex) {
      this.response = ex.response.data
      this.shouldSendToSuperAdminCheck()
    } finally {
      this.loading = false
    }
  }

  shouldSendToSuperAdminCheck() {
    const role = authStore.getUserInfo().role
    const isAdmin = role === Roles.ADMIN

    if (isAdmin) {
      this.errorCount++
      if (this.errorCount > 2) {
        this.sendToSuperAdminOpen = true
      }
    }
  }

  async uploadCsvForSuperAdmin(orgId: string) {
    this.loading = true
    const file = this.file
    const formData = new FormData()
    formData.append('file', file)
    try {
      const response = await uploadCsvForSuperadmin(orgId, formData)
      this.response = response.data
      this.errorCount = 0
    } catch (ex) {
      this.response = ex.response.data
    } finally {
      this.loading = false
      this.sendToSuperAdminOpen = false
    }
  }

  async fetchOrgs() {
    this.loadingOrgs = true
    const orgs = await getUserOrgs()
    const options = orgs.data.map((org) => ({ value: org.orgId, label: org.orgName }))
    this.orgOptions = options
    this.loadingOrgs = false
  }
}

export const applicantBatchStore = new ApplicantBatchStore()
