import React from 'react'

export interface Option {
  value: string
  label: string
}

interface Props {
  className?: string
  children?: React.ReactNode
  options: Array<Option>
}

const Select = React.forwardRef<HTMLSelectElement & { disabled: boolean; options: Option[] }>(
  ({ options, ...rest }: Props, ref) => {
    return (
      <select
        id="org"
        className="max-w-md mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        {...rest}
        ref={ref}
      >
        <option value="">Select an Option</option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    )
  },
)

export default Select
