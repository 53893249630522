import { api } from '../api'
import { getHeaders } from '../get-headers'

export const saveAnswers = (answerInfo: any) => {
  console.log('saveAnswers', answerInfo)

  return api.post(`/assessment`, answerInfo, {
    ...getHeaders(),
  })
}
