import memoize from 'fast-memoize'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Dashboard } from '../api/applicant'
import { applicantStore } from '../stores/applicant-store'
import { AssessmentInfo } from './assessment-info'
import { BundleInfo } from './bundle-info'
import { BundleMenu } from './bundle-menu'

export const Applicant = observer((props) => {
  const [selectedDashboard, setSelectedDashboard] = React.useState<Dashboard>()
  const [showNoteMessage, setShowNoteMessage] = React.useState<boolean>()
  const [startAssessment, setStartAssessment] = React.useState<boolean>()

  const bundleClick = React.useCallback(
    memoize((dashboard) => () => {
      setSelectedDashboard(dashboard)
    }),
    [],
  )

  const onStart = React.useCallback(() => {
    setStartAssessment(true)
  }, [])

  const onExit = React.useCallback(() => {
    setStartAssessment(false)
  }, [])

  const onStartQuestions = React.useCallback((bundleId) => {
    window.location.href = `/assessment.asp?id=${bundleId}`
  }, [])

  React.useEffect(() => {
    const fn = async () => {
      await applicantStore.getDashboard()
      await new Promise((r) => setTimeout(r, 1))
      setSelectedDashboard(applicantStore.dashboard[0])
      setShowNoteMessage(applicantStore.dashboard.length > 1)
    }

    fn()
  }, [])

  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        {!startAssessment && (
          <div className="d-flex flex-row mt-2 col-md-12 custom-tab p-0">
            <BundleMenu
              bundleClick={bundleClick}
              dashboard={applicantStore.dashboard}
              selectedDashboard={selectedDashboard}
            />

            <BundleInfo
              dashboard={selectedDashboard}
              isCompleted={selectedDashboard?.totalAnswered >= selectedDashboard?.totalQuestions}
              showNoteMessage={showNoteMessage}
              onStart={onStart}
            />
          </div>
        )}

        {startAssessment && <AssessmentInfo dashboard={selectedDashboard} onStart={onStartQuestions} onExit={onExit} />}
      </div>
    </div>
  )
})
