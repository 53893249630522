import { makeAutoObservable } from 'mobx'
import * as applicantApi from '../api/applicant'
import { Dashboard } from '../api/applicant'

class ApplicantStore {
  _dashboard: Dashboard[]

  get dashboard() {
    return this._dashboard
  }

  set dashboard(value) {
    // console.trace('set')
    this._dashboard = value
  }

  constructor() {
    makeAutoObservable(this)
  }

  async getDashboard() {
    const { data } = await applicantApi.getDashboard()

    this.dashboard = data
  }
}

export const applicantStore = new ApplicantStore()
