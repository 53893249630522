import './tailwind.css'
import * as applicant from './applicant'
import * as applicantMenu from './applicant/applicant-menu'
import * as assessment from './assessment'
import * as applicantBatch from './applicant-batch'
import * as applicantCsvUploads from './applicant-csv-uploads'
import './index.css'
import * as login from './login'

login.register()
applicantMenu.register()
applicant.register()
assessment.register()
applicantBatch.register()
applicantCsvUploads.register()
