import React from 'react'
import YesIcon from './yes-icon'
import NoIcon from './no-icon'

interface Props {
  completed: boolean
  sentToProgram: boolean
  sentToInterviewWise: boolean
}

const AssessmentStatus = ({ completed, sentToProgram, sentToInterviewWise }: Props) => {
  return (
    <ul className="list-none">
      <StatusItem checked={completed} label="Assessment Submitted to SelectWise" yesLabel="Yes" noLabel="No" />
    </ul>
  )
}

interface StatusItemProps {
  checked: boolean
  label: string
  yesLabel: string
  noLabel: string
}

const StatusItem = ({ checked, label, yesLabel, noLabel }: StatusItemProps) => {
  const resultLabel = checked ? yesLabel : noLabel

  return (
    <li className="mb-4">
      <span>{checked ? <YesIcon /> : <NoIcon />}</span>
      <span className="ml-3">
        {label}
        {': '}
      </span>
      <span className="font-bold">{resultLabel}</span>
    </li>
  )
}

export default AssessmentStatus
