import { Duration } from 'luxon'
import * as React from 'react'
import { Dashboard } from '../../api/applicant'
import { applicantStore } from '../../stores/applicant-store'
import AssessmentStatus from './assessment-status'

interface Props {
  dashboard: Dashboard
  showNoteMessage: boolean
  isCompleted: boolean
  onStart: () => void
}

export const BundleInfo = (props: Props) => {
  const { dashboard, showNoteMessage, isCompleted, onStart } = props
  const { bundle } = dashboard || {}

  const bundleInfo = applicantStore.dashboard?.find((d) => d.bundle.bundleId === bundle?.bundleId)

  return (
    <div className="tab-content">
      <div className="tab-pane fade show active" id="lorem" role="tabpanel">
        <div className="col-md-12 applicant_dash_cnt" id="firstTest">
          <div className="col-md-12 text-right pr-2 start-btns">
            {bundle && (
              <button
                className={`btn btn-light btn-md ${isCompleted ? 'disabled' : ''}`}
                id="bid"
                onClick={onStart}
                disabled={isCompleted}
              >
                {!isCompleted ? 'START ASSESSMENT' : 'ASSESSMENT COMPLETED'}
              </button>
            )}
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6"></div>
            </div>
          </div>
          <div id="exam-main">
            <div className="col-md-12 text-center py-4">
              <div className="icon_img">
                <span>
                  <img id="orgImg" src="" className="logo_img" />
                </span>
              </div>
            </div>
            <div className="row mx-0 mt-4">
              <div className="col-md-6">
                <ul className="applicant_list">
                  <li>
                    <span className="details">Date Assigned:</span>{' '}
                    <span id="dataAssigned">
                      {bundleInfo?.applicantBundleAssignment?.dateAssigned && (
                        <>{new Date(bundleInfo?.applicantBundleAssignment?.dateAssigned).toLocaleString()}</>
                      )}
                    </span>
                  </li>
                  <li>
                    <span className="details">Total Questions:</span>{' '}
                    <span id="totalQuestion">{bundleInfo?.totalQuestions}</span>
                  </li>
                  <li>
                    <span className="details">Total Answered Questions:</span>{' '}
                    <span id="totalAnsweredQuestion">{bundleInfo?.totalAnswered}</span>
                  </li>
                  <li>
                    <span className="details">Start Date:</span>
                    <span id="startData">
                      {!bundleInfo?.applicantBundleAssignment?.bundleStartTime && <>N/A</>}
                      {bundleInfo?.applicantBundleAssignment?.bundleStartTime && (
                        <>{new Date(bundleInfo?.applicantBundleAssignment?.bundleStartTime).toLocaleString()}</>
                      )}
                    </span>
                  </li>
                  <li>
                    <span className="details">End Date:</span>
                    <span id="endDate">
                      {!bundleInfo?.applicantBundleAssignment?.bundleEndTime && <>N/A</>}
                      {bundleInfo?.applicantBundleAssignment?.bundleEndTime && (
                        <>{new Date(bundleInfo?.applicantBundleAssignment?.bundleEndTime).toLocaleString()}</>
                      )}
                    </span>
                  </li>

                  <li>
                    <span className="details">Total Time(hr:min:sec):</span>{' '}
                    <span id="totalTime">
                      {Duration.fromObject({ seconds: bundleInfo?.totalTime }).toFormat('hh:mm:ss')}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 text-left">
                <AssessmentStatus
                  completed={isCompleted}
                  sentToProgram={bundleInfo?.orgApplicants.resultsSetntoProgram}
                  sentToInterviewWise={bundleInfo?.orgApplicants.programSentInterviewInvite}
                />
              </div>
              <div className="col-md-12">
                {showNoteMessage && (
                  <>
                    <br />
                    Note: Some programs may use a few of the same items. If this is the case, you will not need to
                    complete any redundant question twice.
                  </>
                )}
              </div>
              <div className="col-md-12 text-center font-bold">
                    Results are sent to the program only after the deadline has passed.
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
