import * as React from 'react'
import { Dashboard } from '../../api/applicant'
import { authStore } from '../../stores/auth-store'
import styles from './assessment-info.module.css'

interface Props {
  dashboard: Dashboard
  onStart: (bundleId: number) => void
  onExit: () => void
}

export const AssessmentInfo = (props: Props) => {
  const userInfo = authStore.getUserInfo()

  const instructionsHtml = (() => {
    const d = document.createElement('div')
    d.innerHTML = props.dashboard.bundle.instructions

    return d.childNodes[0].nodeValue
  })()

  const startAssessment = React.useCallback(() => {
    props.onStart(props.dashboard.bundle.bundleId)
  }, [])

  return (
    <div className={`w-3/4 mx-auto ${styles.container}`}>
      <h1 className="mb-4">Applicant Fit Assessment for {userInfo.firstName}</h1>

      <div className={`flex flex-auto ${styles.gaps}`}>
        <div className={`flex flex-auto items-center ${styles.block}`}>
          <img src={`images/${props.dashboard.org.orgLogo || 'no_image.png'}`} className={`${styles.logo} mr-4`} />
          <h2>{props.dashboard.bundle.bundleExternalName}</h2>
        </div>
        <div className={`flex flex-auto flex-col`}>
          <div className={`${styles.block} p-3 mb-2 flex`}>
            <div className="flex-auto">Total Questions</div>
            <div className="flex-auto text-right mr-2">{props.dashboard.totalQuestions}</div>
          </div>
          <div className={`${styles.block} p-3 flex`}>
            <div className="flex-auto">Total Answered Questions</div>
            <div className="flex-auto text-right mr-2">{props.dashboard.totalAnswered}</div>
          </div>
        </div>
      </div>

      <div className={`${styles.blockNoColor} mt-3 p-4`}>
        <h3 className="mb-2">Instructions</h3>

        <div>
          <p dangerouslySetInnerHTML={{ __html: instructionsHtml }} />
        </div>
      </div>

      <div className="text-right mt-4">
        <button type="button" className="btn btn-danger px-6 py-2 mr-2" onClick={props.onExit}>
          Exit
        </button>
        <button type="submit" className="btn btn-success px-6 py-2" onClick={startAssessment}>
          Start Assessment
        </button>
      </div>
    </div>
  )
}
