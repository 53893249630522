import { makeAutoObservable } from 'mobx'
import * as assessmentApi from '../api/assessment'
import { QuestionInfo } from '../api/assessment'

class AssessmentStore {
  questionInfo: QuestionInfo
  questionTimer: number
  currentPreviewingQuestion: number

  constructor() {
    this.currentPreviewingQuestion = 0
    makeAutoObservable(this)
  }

  async getNextQuestion(bundleId: number) {
    const { data } = await assessmentApi.getNextquestion(bundleId)

    this.questionInfo = data
    this.questionTimer = data.summaryInfo.timeOnCurrent
  }

  async previewCurrentQuestion(bundleId: number) {
    const { data } = await assessmentApi.previewQuestion(bundleId, this.currentPreviewingQuestion)
    this.questionInfo = data
  }

  async previewNextQuestion(bundleId: number) {
    this.currentPreviewingQuestion++
    const { data } = await assessmentApi.previewQuestion(bundleId, this.currentPreviewingQuestion)
    this.questionInfo = data
  }

  setQuestionTimer(timer: number) {
    console.log('timer', timer)

    this.questionTimer = timer || 0
  }

  tickQuestionTimer() {
    this.questionTimer++
  }

  saveAnswers(data: any) {
    return assessmentApi.saveAnswers(data)
  }

  saveAndExit(data: any) {
    return assessmentApi.saveAndExit(data)
  }
}

export const assessmentStore = new AssessmentStore()
