import React, { ReactNode } from 'react'
import classNames from 'classnames'

export interface FormErrorProps {
  className?: string
  children?: ReactNode
}

export default function FormError({ className, children }: FormErrorProps) {
  return <div className={classNames(className, 'text-xs text-red-500')}>{children}</div>
}
