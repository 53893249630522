import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { QuestionInfo } from '../../api/assessment'

interface Props {
  questionInfo: QuestionInfo
}

export const ResponseInput = ({ questionInfo }: Props) => {
  const { responses, reactions } = questionInfo || {}

  const { register } = useFormContext()

  if (questionInfo?.questionType?.qTypeId === 4) {
    return null
  }

  if (!responses || !responses.length) {
    return null
  }

  return (
    <div className="mt-4">
      <table className="table table-bordered custom-radios qna_table">
        <thead>
          <tr>
            <th></th>

            {responses?.map((res) => (
              <th key={res.responseOptionId} className="text-center">
                <div dangerouslySetInnerHTML={{ __html: res.responseOptionText }} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {reactions?.map((rea, reaIndex) => (
            <tr key={rea.reactionId}>
              <td>
                <div dangerouslySetInnerHTML={{ __html: rea.reactionText }} />
              </td>

              {responses?.map((res, resIndex) => (
                <td key={res.responseOptionId} className="text-center">
                  <input
                    type="hidden"
                    defaultValue={rea.reactionId}
                    {...register(`reactionResponse.${reaIndex}.reaction`)}
                  />
                  <input
                    type="radio"
                    defaultValue={resIndex + 1}
                    {...register(`reactionResponse.${reaIndex}.response`)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
