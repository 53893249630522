import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { FieldError, FieldErrors } from 'react-hook-form'
import FormError from './FormError'
import FormLabel from './FormLabel'

export interface FormFieldProps {
  name: string
  errors?: FieldErrors
  id?: string
  label?: ReactNode
  className?: string
  labelClassName?: string
  children?: ReactNode
  noMargin?: boolean
}

export default function FormField({
  errors,
  id,
  label,
  className,
  labelClassName,
  children,
  name,
  noMargin,
}: FormFieldProps) {
  const error = errors[name] as FieldError | undefined
  return (
    <div className={classNames(className, { 'mb-4': !noMargin })}>
      {label && (
        <FormLabel className={labelClassName} htmlFor={id}>
          {label}
        </FormLabel>
      )}
      {children}
      {error && <FormError className="m-1">{error.message}</FormError>}
    </div>
  )
}
