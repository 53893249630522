import { makeAutoObservable } from 'mobx'
import { ApplicantCsvUpload, getApplicantsCsvUploads, downloadFile } from './api'

class ApplicantCsvUploadsStore {
  public uploads: ApplicantCsvUpload[]
  public loading: boolean

  constructor() {
    makeAutoObservable(this)
  }

  async getUploads() {
    this.loading = true
    const response = await getApplicantsCsvUploads()
    const uploads = response.data
    this.uploads = uploads
    this.loading = false
  }

  async downloadFile(uploadId) {
    const response = await downloadFile(uploadId)
    const type = response.headers['content-type']
    const blob = new Blob([response.data], { type: type })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = response.headers['x-filename']
    link.click()
  }
}

export const applicantCsvUploadsStore = new ApplicantCsvUploadsStore()
