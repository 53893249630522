import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { ApplicantBatch } from '.'
import { getPath } from '../get-path'

export const register = () => {
  const path = getPath()

  if (!/\/applicant_batch1/i.test(path)) {
    return
  }

  const domContainer = document.querySelector('#applicant-batch')

  ReactDOM.render(<ApplicantBatch />, domContainer)
}
