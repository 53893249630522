import React from 'react'
import { CSVApplicant } from '../../store'

interface Props {
  content: Array<CSVApplicant>
}

export const PreviewTable = ({ content }: Props) => {
  if (!content || !content.length) {
    return null
  }

  const headers = Object.keys(content[0])

  return (
    <div className="flex flex-col max-w-5xl mx-auto border-none">
      <div className="-my-2 overflow-x-auto overflow-auto max-h-96 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Line Num.
                  </th>
                  {headers.map((header) => (
                    <th
                      key={header}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {content.map((line, i) => {
                  const values = Object.values(line) as Array<string>
                  return (
                    <tr key={i} className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{i + 2}</td>
                      {values.map((cell, i) => (
                        <td key={cell + i} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
