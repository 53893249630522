import { cookies } from '../services/cookies'

export const getHeaders = ({ download } = { download: false }) => {
  const accessToken = cookies.get('access_token')

  const headerValues = [accessToken && { key: 'authorization', value: `Bearer ${accessToken}` }]
    .filter(Boolean)
    .reduce((a, b) => {
      a[b.key] = b.value

      return a
    }, {})

  return {
    headers: {
      ...headerValues,
      ...(download ? { responseType: 'arrayBuffer' } : {}),
    },
  }
}
